import { KeycloakService } from '@elotech/components';
import axios, { AxiosPromise } from 'axios';

import { EnumDTO, ModuloDTO } from '../type';

const resource = () =>
  `${KeycloakService.getInstance().authServerUrl}/realms/${
    KeycloakService.getInstance().realm
  }/modulos`;

export const getModulos = (idEntidade: string): AxiosPromise<EnumDTO[]> =>
  axios.get(`${resource()}/atual/${idEntidade}`);

export const getModulosAtivos = (tenant: any): AxiosPromise<ModuloDTO[]> =>
  axios.get(`/console-api/api/modulos/feature-toggle/${tenant}`);

export const buildModuloExternoUrl = (
  idModuloExterno: string
): AxiosPromise<any> =>
  axios.get(`/painel-api/api/modulos-externos/${idModuloExterno}/url`);
