import './Certificado.css';

import {
  ActionButton,
  ActionsGroup,
  Alert,
  Chip,
  Container,
  FormattedDateTime,
  Panel,
  Table
} from '@elotech/components';
import { UserCertificateService } from 'common/service';
import { Certificado } from 'common/type/Certificado';
import { Formik } from 'formik';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

const CertificadoPage: React.FC = () => {
  const user = useSelector((state: any) => state.auth.user);
  const [loadingTable, setLoadingTable] = useState<boolean>(false);
  const [certificados, setCertificados] = useState<any[]>([]);
  const [isAddingCertificate, setIsAddingCertificate] = useState<boolean>(
    false
  );

  const fileInputRef = useRef<HTMLInputElement>(null);

  const findAllCertificates = useCallback(() => {
    setLoadingTable(true);
    UserCertificateService.findAll(user.id)
      .then((certificados: any) => setCertificados(certificados.data))
      .catch(() => {
        handleAlert('error', 'Erro ao buscar certificados');
      })
      .finally(() => setLoadingTable(false));
  }, [user.id]);

  useEffect(() => {
    const userId = user?.id;
    if (userId) {
      findAllCertificates();
    }
  }, [user, findAllCertificates]);

  const [certificateState, setCertificateState] = useState<{
    filename: string | null;
    certificado: any | null;
    password: string | null;
  }>({ certificado: null, password: null, filename: null });

  const addCertificate = (certificate: any) => {
    setIsAddingCertificate(true);
    setLoadingTable(true);

    UserCertificateService.save({
      contentBase64: certificate.certificado,
      password: certificate.password,
      filename: certificate.filename
    })
      .then(() => {
        handleAlert('success', 'Certificado adicionado com sucesso');
        findAllCertificates();
        resetFormState();
      })
      .catch(error => {
        handleAlert('error', 'Erro ao adicionar certificado', error);
        setLoadingTable(false);
      })
      .finally(() => {
        setIsAddingCertificate(false);
      });
  };

  const resetFormState = () => {
    setCertificateState({
      certificado: null,
      password: null,
      filename: null
    });

    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const onRemoveCertificate = (itemToDelete: Certificado) => {
    Alert.question({
      title: `Deseja realmente excluir o certificado  ${itemToDelete?.info
        .apelido ?? itemToDelete?.info.filename}?`
    }).then((result: any) => {
      if (result.value) {
        setLoadingTable(true);
        UserCertificateService.deleteCertificate(itemToDelete.certificateId)
          .then(() => {
            findAllCertificates();
            handleAlert('success', 'Certificado excluído com sucesso');
          })
          .catch(error =>
            handleAlert('error', 'Erro ao excluir certificado', error)
          )
          .finally(() => setLoadingTable(false));
      }
    });
  };

  const onFavoriteCertificate = (item: Certificado) => {
    setLoadingTable(true);

    UserCertificateService.setPrincipal(item.certificateId)
      .then(() => {
        const certificadoPrincipalAction = item.info?.principal
          ? 'desmarcado'
          : 'marcado';

        handleAlert(
          'success',
          `Certificado ${certificadoPrincipalAction} como principal`
        );

        findAllCertificates();
      })
      .catch(error => {
        handleAlert(
          'error',
          'Erro ao marcar certificado como principal',
          error
        );
      })
      .finally(() => setLoadingTable(false));
  };

  const handleAlert = (
    type: 'success' | 'error',
    message: string,
    error?: any
  ) => {
    if (type === 'success') {
      Alert.success({ title: message });
    } else {
      Alert.error({ title: message }, error);
    }
  };

  return (
    <Container title="Certificados" icon={'user'}>
      <div className="usuario-certificado-page">
        <Formik
          initialValues={certificateState}
          onSubmit={() => {
            addCertificate(certificateState);
          }}
          enableReinitialize={true}
        >
          {formProps => (
            <div className="row mb-xs">
              <div className="col-md-6 ">
                <div className="form-group">
                  <label className="label" htmlFor="field">
                    Certificado
                  </label>
                  <div
                    className="file-uploader"
                    style={{ backgroundColor: 'white' }}
                  >
                    <input
                      type="file"
                      id="upload-file-input"
                      accept=".pfx"
                      ref={fileInputRef}
                      onChange={file => {
                        const reader = new FileReader();

                        let filenameTemp: string | null = null;

                        reader.onload = () => {
                          const content = reader.result as string;
                          const partialBase64 = content.indexOf('base64,');
                          setCertificateState({
                            ...certificateState,
                            certificado: content.substring(
                              partialBase64 !== -1 ? partialBase64 + 7 : 0,
                              content.length
                            ),
                            filename: filenameTemp
                          });
                        };

                        if (file.target.files && file.target.files.length > 0) {
                          const fileReader = file.target.files[0];
                          filenameTemp = fileReader.name;
                          reader.readAsDataURL(fileReader);
                        } else {
                          setCertificateState({
                            ...certificateState,
                            certificado: null,
                            filename: null
                          });
                        }
                      }}
                      className="file-uploader-input"
                      title="Clique ou arraste para anexar"
                    />

                    <label htmlFor="upload-file-input" className="input">
                      {certificateState.filename
                        ? certificateState.filename
                        : 'Clique ou arraste para anexar'}
                    </label>

                    <label
                      htmlFor="upload-file-input"
                      className="file-uploader-icon"
                    ></label>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label className="label" htmlFor="field">
                    Senha
                    <div className="hint inline clean fa-question-circle module-color sm">
                      <div className="hint-content">
                        Caso não tenha a senha vinculada, será necessário
                        inseri-la sempre que utilizar o certificado.
                      </div>
                    </div>
                  </label>
                  <input
                    type="password"
                    autoComplete="new-password"
                    id="field"
                    placeholder="Digite a senha do certificado"
                    className="input"
                    name="password"
                    onChange={e =>
                      setCertificateState({
                        ...certificateState,
                        password: e.target.value
                      })
                    }
                    value={certificateState.password ?? ''}
                  />
                </div>
              </div>
              <div className="col-md-3">
                <button
                  type="submit"
                  disabled={
                    !certificateState.certificado ||
                    isAddingCertificate ||
                    loadingTable
                  }
                  style={{ marginTop: '29px' }}
                  onClick={() => {
                    formProps.submitForm();
                  }}
                  className="btn positive icon-left"
                >
                  <em className="fa fa-plus"></em>
                  Adicionar
                </button>
              </div>
            </div>
          )}
        </Formik>

        <Panel isTable>
          <Table
            loading={loadingTable}
            values={certificados}
            highlightSuccess={e => e.info?.principal}
            keyExtractor={(item, index) => `certificate-${index}`}
          >
            <Table.Column<any>
              header="Apelido"
              value={item => item?.info?.apelido ?? item?.info.filename}
            />
            <Table.Column<any>
              header="Tipo"
              value={item => item?.info?.tipo ?? 'Senha não informada'}
            />
            <Table.Column<any>
              header="CPF/CNPJ"
              value={item => item?.info?.cnpjCpf ?? 'Senha não informada'}
            />
            <Table.Column<any>
              header="Fim da Validade"
              value={item =>
                item?.info.dataFimValidade ? (
                  <>
                    <FormattedDateTime
                      value={item?.info.dataFimValidade}
                      timeZone="UTC"
                    />
                  </>
                ) : (
                  'Senha não informada'
                )
              }
            />
            <Table.Column<any>
              header="Status"
              value={item =>
                item?.info.dataFimValidade ? (
                  <>
                    <Chip
                      value={item.info.valid ? 'Válido' : 'Inválido'}
                      color={item.info.valid ? 'positive' : 'negative'}
                    />
                  </>
                ) : (
                  'Senha não informada'
                )
              }
            />
            <Table.Column<any>
              header=""
              value={item => (
                <ActionsGroup>
                  <ActionButton
                    customClass={
                      item?.info?.principal ? 'principal-button' : ''
                    }
                    key="action-button-principal"
                    icon={'star'}
                    label={
                      item?.info.principal
                        ? 'Desmarcar como principal'
                        : 'Marcar como principal'
                    }
                    onClick={() => {
                      onFavoriteCertificate(item);
                    }}
                  />

                  <ActionButton
                    key="action-button-remove"
                    icon={'trash'}
                    label={'Remover certificado'}
                    onClick={() => {
                      onRemoveCertificate(item);
                    }}
                  />
                </ActionsGroup>
              )}
            />
          </Table>
        </Panel>
      </div>
    </Container>
  );
};

export default CertificadoPage;
