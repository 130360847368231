import { ContratanteService } from '@elotech/painel/src/service';
import React from 'react';
import { createContext, useEffect, useState } from 'react';

type Props = {};

const initialContext = {
  emailContratante: '',
  emailElotech: '@elotech.com.br'
};

export const ContratanteContext = createContext(initialContext);

const ContratanteProvider: React.FC<Props> = ({ children }) => {
  const [emailContratante, setEmailContratante] = useState('');

  useEffect(() => {
    ContratanteService.findEmailDomain().then(({ data }) =>
      setEmailContratante(data)
    );
  }, []);

  return (
    <ContratanteContext.Provider
      value={{ ...initialContext, emailContratante }}
    >
      {children}
    </ContratanteContext.Provider>
  );
};

export default ContratanteProvider;
