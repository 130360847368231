import axios, { AxiosPromise } from 'axios';

export const findAllBySectorConfig = (id: string): AxiosPromise<any[]> => {
  return axios.get(`/painel-api/api/sector/find-all-by-sector-config/${id}`);
};

export const saveAllBySectorConfig = (
  id: string,
  operations: any[]
): AxiosPromise<any> => {
  return axios.post(
    `/painel-api/api/sector/save-all-by-sector-config/${id}`,
    operations
  );
};
