import axios, { AxiosPromise } from 'axios';
import { Certificado } from 'common/type/Certificado';

const PAINEL_API = '/painel-api/api/user-certificate';

export const save = (body: {
  filename: string | null;
  contentBase64: string;
  password: string | null;
}): AxiosPromise<Certificado> => {
  return axios.post(`${PAINEL_API}`, body);
};

export const findAll = (id: any): AxiosPromise<any[]> => {
  return axios.get(`${PAINEL_API}/${id}`);
};

export const setPrincipal = (id: any): AxiosPromise<void> => {
  return axios.put(`${PAINEL_API}/principal/${id}`);
};

export const deleteCertificate = (id: any): AxiosPromise<void> => {
  return axios.delete(`${PAINEL_API}/${id}`);
};
