import { ActionButton, ActionsGroup, Table } from '@elotech/components';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import React from 'react';

import { GrupoPermissao } from '../../type';

type WithActionProps = {
  icon: IconProp;
  label: string;
  loading: boolean;
  values: GrupoPermissao[];
  onAction: (grupo: GrupoPermissao) => void;
  readOnly: false;
  emptyMessage: string;
};

type ReadOnlyProps = {
  loading: boolean;
  values: GrupoPermissao[];
  readOnly: true;
  emptyMessage: string;
};
type Props = WithActionProps | ReadOnlyProps;

function hasActions(
  props: WithActionProps | ReadOnlyProps
): props is WithActionProps {
  return props.readOnly !== true;
}
const GrupoSelectorTable: React.FC<Props> = props => {
  return (
    <Table
      loading={props.loading}
      values={props.values}
      messageEmpty={props.emptyMessage}
    >
      <Table.Column<GrupoPermissao> value={item => item.nome} header={'Nome'} />
      <Table.Column<GrupoPermissao>
        value={item => item.descricao}
        header={'Descrição'}
      />
      <Table.Column<GrupoPermissao>
        header=""
        value={item => (
          <ActionsGroup>
            {hasActions(props) && (
              <ActionButton
                key="action-button"
                icon={props.icon}
                label={props.label}
                onClick={() => props.onAction(item)}
              />
            )}
            <ActionButton
              key="view-button"
              icon={'eye'}
              label={'Visualizar'}
              path={`/grupos-permissoes/${item.id}`}
              newTab
            />
          </ActionsGroup>
        )}
      />
    </Table>
  );
};

export default GrupoSelectorTable;
