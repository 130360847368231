import { PageRequest, PagedResponse, Sort } from '@elotech/components';
import axios, { AxiosPromise } from 'axios';

import { CentralComunicacao } from '../type/CentralComunicacao';

const CENTRAL_COMUNICACAO_API = '/painel-api/api/centrais-comunicacoes';

export const findAll = (
  search: string,
  pagination?: PageRequest,
  sort: Sort = { sort: 'createdAt,desc' }
): AxiosPromise<PagedResponse<CentralComunicacao>> =>
  axios.get(CENTRAL_COMUNICACAO_API, {
    params: {
      search,
      ...pagination,
      ...sort
    }
  });

export const findById = (id: string): AxiosPromise<CentralComunicacao> => {
  return axios.get(`${CENTRAL_COMUNICACAO_API}/${id}`);
};

export const testeNotificacao = async (envioMensagem: CentralComunicacao) => {
  return await axios.post(
    `${CENTRAL_COMUNICACAO_API}/test-notification`,
    envioMensagem
  );
};

export const save = (centralComunicacao: CentralComunicacao) => {
  return axios.post(`${CENTRAL_COMUNICACAO_API}`, centralComunicacao);
};
